
<template>
  <div class="common-layout">
    <div class="scroll">
      <el-container>
        <el-header class="nav" :class="navBarFixed == true ? 'navBarWrap' : ''">
          <div class="iconLeft">
            <img src="../assets/image/logo.png" alt="">
          </div>
          <div class="navigation">
            <el-menu 
              :default-active="activeIndex2" 
              class="el-menu-demo" 
              mode="horizontal" 
              background-color="#091928"
              text-color="#afb5b9" 
              active-text-color="#fff" 
              :ellipsis="false" 
              :router="true">
              <el-menu-item style="font-size: 0.9vw;" index="/">首页</el-menu-item>
              <el-sub-menu index="2">
                <template #title>操作</template>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/chat">智能开发</el-menu-item>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/chatText">生成文本</el-menu-item>
                <!-- <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/createImage">生成图像</el-menu-item> -->
              </el-sub-menu>
              <el-sub-menu index="3">
                <template #title>文档</template>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/onlineGeneration">在线生成说明</el-menu-item>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/clientHelp">客户端操作说明</el-menu-item>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/buildSpringboot">设计文档说明</el-menu-item>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/webDocument">web-vue 帮助文档</el-menu-item>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/springboot">构建 springboot</el-menu-item>
              </el-sub-menu>
              <!-- <el-sub-menu index="4">
                <template #title>应用</template>
                <el-sub-menu index="4-1">
                  <template #title>Java</template>
                  <el-menu-item style="font-size: 0.9vw;" index="/projectSpringboot">springboot项目
                  </el-menu-item>
                  <el-menu-item style="font-size: 0.9vw;" index="/projectSocket-netty">socket-netty项目
                  </el-menu-item>
                  <el-menu-item style="font-size: 0.9vw;" index="/projectSpringboot-kafka">
                    springboot-kafka项目</el-menu-item>
                  <el-menu-item style="font-size: 0.9vw;" index="/projectPython-flask">bigdata-flink项目
                  </el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="4-2">
                  <template #title>python</template>
                  <el-menu-item style="font-size: 0.9vw;" index="/pythonFlask">
                    python-flask项目
                  </el-menu-item>
                </el-sub-menu>
              </el-sub-menu> -->
              <el-sub-menu index="5">
                <template #title>高级</template>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/basic">基础配置</el-menu-item>
                <!-- <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/socketProject">socket项目
                </el-menu-item>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/callInterfaceProject">调用接口类型项目
                </el-menu-item> -->
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/statis">数据统计</el-menu-item>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/super">关联表达式</el-menu-item>
              </el-sub-menu>
              <!-- <el-sub-menu index="6">
                <template #title>案例</template>
                <el-sub-menu index="6-1">
                  <template #title>Java</template>
                  <el-menu-item style="font-size: 0.9vw;" index="/caseProjectSpringboot">springboot项目
                  </el-menu-item>
                  <el-menu-item style="font-size: 0.9vw;" index="/caseProjectSocket-netty">
                    socket-netty项目
                  </el-menu-item>
                  <el-menu-item style="font-size: 0.9vw;" index="/caseProjectSpringboot-kafka">
                    springboot-kafka项目</el-menu-item>
                  <el-menu-item style="font-size: 0.9vw;" index="/caseProjectPython-flask">
                    bigdata-flink项目
                  </el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="6-2">
                  <template #title>python</template>
                  <el-menu-item style="font-size: 0.9vw;" index="/casePythonFlask">
                    python-flask项目
                  </el-menu-item>
                </el-sub-menu>
              </el-sub-menu> -->
              <el-sub-menu index="6">
                <template #title>应用</template>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/java">java</el-menu-item>
              </el-sub-menu>
              <el-menu-item style="font-size: 0.9vw;" index="/download">下载</el-menu-item>
              <el-sub-menu index="8">
                <template #title>帮助</template>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/commonProblem">常见问题</el-menu-item>
                <el-menu-item class="menu2" style="font-size: 0.9vw;" index="/contactUs">联系我们</el-menu-item>
              </el-sub-menu>
              <el-menu-item style="font-size: 0.9vw;" index="/info">简介</el-menu-item>
            </el-menu>
          </div>
          <div class="iconLocation">
            <!-- <div class="no-inherit" @click="jumpLogin">登录</div> -->
            <el-icon class="no-inherit" :size="size" :color="color">
              <el-icon @click="jumpLogin">
                <User />
              </el-icon>
            </el-icon>
            <!-- Or use it independently without derive attributes from parent -->
          </div>
        </el-header>
      </el-container>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import router from '@/router'
import { useRoute } from 'vue-router'
export default {
  data() {
    return {
      navBarFixed: false,
    };
  },

  components: {},
  setup() {
    let size = ref('24px')
    let color = ref('#fff')
    const route = useRoute()
    const activeIndex2 = ref('')

    function jumpLogin() {
      router.push('/userinfo')
      // window.location.href = '/userinfo'
    }
    return {
      activeIndex2,
      jumpLogin,
      route,
      size,
      color
    }
  },
  mounted() {
    window.addEventListener("scroll", this.watchScroll);
  },

  methods: {
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //  当滚动超过 90 时，实现吸顶效果
      if (scrollTop > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    }
  }
}
</script>
<style scoped>
@import "./leftSide.scss";

.menu2:hover {
  color: #fff !important;
  /*text-decoration: underline; */
}
</style>

