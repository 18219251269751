/** @format */

import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/custom/home/index.vue'),
    meta: { keepAlive: true },
  },
  // 用户信息
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/common/user/register.vue'),
    meta: { keepAlive: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/common/user/login.vue'),
    meta: { keepAlive: true },
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: () => import('@/views/common/user/userinfo.vue'),
    meta: { keepAlive: true },
  },
  // 绑定手机
  {
    path: '/bindMobile',
    name: 'bindMobile',
    component: () => import('@/views/common/user/bindMobile.vue'),
    meta: { keepAlive: true },
  },
  // 修改绑定手机
  {
    path: '/updateBindMobile',
    name: 'updateBindMobile',
    component: () => import('@/views/common/user/updateBindMobile.vue'),
    meta: { keepAlive: true },
  },
  // 用户代理
  {
    path: '/userAgent',
    component: () => import('@/views/common/user/userAgent.vue'),
    meta: { keepAlive: true },
  },
  // 申请代理
  {
    path: '/applyAgent',
    component: () => import('@/views/common/user/applyAgent.vue'),
    meta: { keepAlive: true },
  },
  // 服务协议
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/custom/agreement/index.vue'),
    meta: { keepAlive: false },
  },
  // 我的项目
  {
    path: '/myProject',
    name: 'myProject',
    component: () => import('@/views/common/user/myProject/index.vue'),
    meta: { keepAlive: true },
  },
  {
    path: '/loginByMobile',
    name: 'loginByMobile',
    component: () => import('@/views/common/user/loginByMobile.vue'),
    meta: { keepAlive: true },
  },
  {
    path: '/updatePassword',
    name: 'updatePassword',
    component: () => import('@/views/common/user/updatePassword.vue'),
    meta: { keepAlive: true },
  },
  {
    path: '/refreshSecret',
    name: 'refreshSecret',
    component: () => import('@/views/common/user/refreshSecret.vue'),
    meta: { keepAlive: true },
  },
  //会员管理
  {
    path: '/upgradeUser',
    name: 'upgradeUser',
    component: () => import('@/views/common/member/upgradeUser.vue'),
    meta: { keepAlive: true },
  },
  //生成项目
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/custom/generator/chat.vue'),
    meta: { keepAlive: true },
  },
  //生成文档
  {
    path: '/chatText',
    name: 'chatText',
    component: () => import('@/views/custom/generator/chat-text.vue'),
    meta: { keepAlive: true },
  },
  //生成规则
  {
    path: '/chatRule',
    name: 'chatRule',
    component: () => import('@/views/custom/generator/chat-rule.vue'),
    meta: { keepAlive: true },
  },
  // 客户端下载
  {
    path: '/download',
    name: 'download',
    component: () => import('@/views/custom/download/index.vue'),
    meta: { keepAlive: true },
  },
  // 客户端帮助文档
  {
    path: '/clientHelp',
    name: 'clientHelp',
    component: () => import('@/views/custom/clientHelp/index.vue'),
    meta: { keepAlive: true },
  },
  // 在线生成帮助文档
  {
    path: '/onlineGeneration',
    name: 'onlineGeneration',
    component: () => import('@/views/custom/onlineGenerationHelp/index.vue'),
    meta: { keepAlive: true },
  },
  // 生成图像
  {
    path: '/createImage',
    name: 'createImage',
    component: () => import('@/views/custom/createImage/index.vue'),
    meta: { keepAlive: true },
  },
  // 编辑图像
  {
    path: '/editImage',
    name: 'editImage',
    component: () => import('@/views/custom/editImage/index.vue'),
    meta: { keepAlive: true },
  },
  // 生成图像
  {
    path: '/changeImage',
    name: 'changeImage',
    component: () => import('@/views/custom/changeImage/index.vue'),
    meta: { keepAlive: true },
  },

  // 数据库设计文档
  {
    path: '/buildSpringboot',
    name: 'buildSpringboot',
    component: () =>
      import('@/views/custom/document/buildSpringboot/index.vue'),
    meta: { keepAlive: true },
  },
  // 构建springboot
  {
    path: '/springboot',
    name: 'springboot',
    component: () =>
      import('@/views/custom/document/designDocument/index.vue'),
    meta: { keepAlive: true },
  },
  // web-vue文档
  {
    path: '/webDocument',
    name: 'webDocument',
    component: () => import('@/views/custom/document/webDocument/index.vue'),
    meta: { keepAlive: true },
  },
  // 应用-Java-springboot项目
  {
    path: '/projectSpringboot',
    name: 'projectSpringboot',
    component: () =>
      import(
        '@/views/custom/application/javaProject/projectSpringboot/index.vue'
      ),
    meta: { keepAlive: true },
  },
  // 应用-Java-projectSocket-netty项目
  {
    path: '/projectSocket-netty',
    name: 'projectSocket-netty',
    component: () =>
      import(
        '@/views/custom/application/javaProject/projectSocket-netty/index.vue'
      ),
    meta: { keepAlive: true },
  },
  // 应用-Java-projectSpringboot-kafka项目
  {
    path: '/projectSpringboot-kafka',
    name: 'projectSpringboot-kafka',
    component: () =>
      import(
        '@/views/custom/application/javaProject/projectSpringboot-kafka/index.vue'
      ),
    meta: { keepAlive: true },
  },
  // 应用-Java-projectPython-flask项目
  {
    path: '/projectPython-flask',
    name: 'projectPython-flask',
    component: () =>
      import(
        '@/views/custom/application/javaProject/projectPython-flask/index.vue'
      ),
    meta: { keepAlive: true },
  },
  // 应用-python-flask项目
  {
    path: '/pythonFlask',
    name: 'pythonFlask',
    component: () =>
      import('@/views/custom/application/python/pythonFlask/index.vue'),
    meta: { keepAlive: true },
  },
  // 案例-Java-springboot项目
  {
    path: '/caseProjectSpringboot',
    name: 'caseProjectSpringboot',
    component: () =>
      import('@/views/custom/case/javaProject/projectSpringboot/index.vue'),
    meta: { keepAlive: true },
  },
  // 案例-Java-projectSocket-netty项目
  {
    path: '/caseProjectSocket-netty',
    name: 'caseProjectSocket-netty',
    component: () =>
      import('@/views/custom/case/javaProject/projectSocket-netty/index.vue'),
    meta: { keepAlive: true },
  },
  // 案例-Java-projectSpringboot-kafka项目
  {
    path: '/caseProjectSpringboot-kafka',
    name: 'caseProjectSpringboot-kafka',
    component: () =>
      import(
        '@/views/custom/case/javaProject/projectSpringboot-kafka/index.vue'
      ),
    meta: { keepAlive: true },
  },
  // 案例-Java-projectPython-flask项目
  {
    path: '/caseProjectPython-flask',
    name: 'caseProjectPython-flask',
    component: () =>
      import('@/views/custom/case/javaProject/projectPython-flask/index.vue'),
    meta: { keepAlive: true },
  },
  // 案例-python-flask项目
  {
    path: '/casePythonFlask',
    name: 'casePythonFlask',
    component: () => import('@/views/custom/case/pythonFlask/index.vue'),
    meta: { keepAlive: true },
  },
  // 高级-基本配置
  {
    path: '/basic',
    name: 'basic',
    component: () =>
      import('@/views/custom/senior/basic/index.vue'),
    meta: { keepAlive: true },
  },
  // 高级-socket项目
  {
    path: '/socketProject',
    name: 'socketProject',
    component: () => import('@/views/custom/senior/socketProject/index.vue'),
    meta: { keepAlive: true },
  },
  // 高级-调用接口项目类型
  {
    path: '/callInterfaceProject',
    name: 'callInterfaceProject',
    component: () =>
      import('@/views/custom/senior/callInterfaceProject/index.vue'),
    meta: { keepAlive: true },
  },
  // 高级-日常统计
  {
    path: '/statis',
    name: 'statis',
    component: () =>
      import('@/views/custom/senior/statis/index.vue'),
    meta: { keepAlive: true },
  },
  // 高级-超级查询
  {
    path: '/super',
    name: 'super',
    component: () => import('@/views/custom/senior/super/index.vue'),
    meta: { keepAlive: true },
  },
  // 应用-java 应用
  {
    path: '/java',
    name: 'java',
    component: () => import('@/views/custom/application/java/index.vue'),
    meta: { keepAlive: true },
  },
  // 培训
  {
    path: '/train/buyTrain',
    name: 'train/buyTrain',
    component: () => import('@/views/custom/train/buyTrain.vue'),
    meta: { keepAlive: true },
  },
  // 帮助
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/custom/help/index.vue'),
    meta: { keepAlive: true },
  },
  // 帮助-联系我们
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('@/views/custom/help/contactUs.vue'),
    meta: { keepAlive: true },
  },

  // 帮助-常见问题
  {
    path: '/commonProblem',
    name: 'commonProblem',
    component: () => import('@/views/custom/help/commonProblem/index.vue'),
    meta: { keepAlive: true },
  },
  // 简介
  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/custom/introduction/index.vue'),
    meta: { keepAlive: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
