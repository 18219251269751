/** @format */
import ElementPlus from 'element-plus'
import zhLocale from 'element-plus/es/locale/lang/zh-cn'
// import 'element-plus/dist/index.css'
import '@/styles/element/index.scss'
import * as icons from '@element-plus/icons-vue'
import '@/assets/icon'
/**
 * 安装插件
 * @param {*} app vue实例
 */
export function installPlugins(app) {
  // 安装element-ui
  app.use(ElementPlus, {
    locale: zhLocale,
    size: 'small',
  })
  // 安装图标库
  for (var icon in icons) {
    app.component(icon, icons[icon])
  }
}
