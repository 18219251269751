/** @format */

const forsrc_userinfo = 'forsrc_userinfo'
const forsrc_agentId = 'forsrc_agentId'
/* 登录用户信息 */
export function getUserinfo() {
  return sessionStorage.getItem(forsrc_userinfo)
}

export function setUserinfo(userinfo) {
  return sessionStorage.setItem(forsrc_userinfo, userinfo)
}

export function removeUserinfo() {
  return sessionStorage.removeItem(forsrc_userinfo)
}

/* 代理人ID */
export function getAgentId() {
  return sessionStorage.getItem(forsrc_agentId)
}
export function setAgentId(code) {
  return sessionStorage.setItem(forsrc_agentId, code)
}
export function removeAgentId() {
  return sessionStorage.removeItem(forsrc_agentId)
}
