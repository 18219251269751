/** @format */

import axios from 'axios'
import { ElMessageBox, ElMessage } from 'element-plus'
import * as constant from '@/js/constant/const'
import errorCode from '@/js/util/errorCode'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const service = axios.create({
  // 创建axios实例
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: '/api',
  // 超时
  timeout: 60000,
  withCredentials: true,
})

export default service

service.interceptors.request.use(
  // request拦截器，用于加token
  (config) => {
    //refresh token
    if (window.localStorage.getItem('overtime')) {
      const overtime = window.localStorage.getItem('overtime')
      const nowTime = Date.parse(new Date()) // 获取当前时间的时间戳
      const differ = nowTime - overtime // 两个时间的差值
      const minutes = Math.floor(differ / (60 * 1000))
      const refreshMinute = -3
      // console.log('minutes: ' + minutes)
      if (minutes >= 0) {
        window.location.href = '#/login'
      } else {
        if (minutes >= refreshMinute) {
          // 判断token是否过期 如果过期就重新刷新token
          service.post('/business/user/account/refreshToken').then((res) => {
            // 刷新token
            if (res.success) {
              window.localStorage.setItem(constant.token, res.data)
              const nowTime = new Date() // 登陆的当前时间
              const overtime = nowTime.setDate(nowTime.getDate() + constant.token_day) // 24小时过期
              window.localStorage.setItem('overtime', overtime)
            } else {
              window.location.href = '#/login'
            }
          })
        }
      }
    }
    //set token
    let token = localStorage.getItem(constant.token)
    if (token) {
      config.headers.common[constant.authorization] = token
    }
    return config
  },
  (error) => {
    console.log(error)
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  // 响应拦截器
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // const success = res.data.success
    const data = res.data.data
    // const status = res.status
    if (code === 200) {
      return Promise.resolve(data)
    }
    // 获取错误信息
    const msg = errorCode[code] || res.data.message || errorCode['default'] || res.error || res.data.res

    console.log('fail code: ' + code + '. msg: ' + msg)
    handleError(code, msg)
  },
  (error) => {
    // console.log(error)
    let code = 0
    let msg = error.response.statusText
    if (error.response) {
      if (error.response.data) {
        code = error.response.data.code
        msg = error.response.data.message
      } else {
        code = error.response.status
      }
    } else {
      let { message } = error
      if (message == 'Network Error') {
        message = '后端接口连接异常'
      } else if (message.includes('timeout')) {
        message = '系统接口请求超时'
      } else if (message.includes('Request failed with status code')) {
        message = '系统接口' + message.substr(message.length - 3) + '异常'
      }
      msg = '出现错误' //message
    }
    console.log('error code: ' + code + '. msg: ' + msg)
    handleError(code, msg)
    return Promise.reject(msg)
  }
)

function handleError(code, msg) {
  if (code == 500) {
    // return Promise.reject(msg)
  } else if (code < 500) {
    ElMessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      location.href = '/login'
      localStorage.clear()
    })
  } else if (code >= 1000) {
    if (code >= 1005 && code <= 1010) {
      //服务器授权失败
      location.href = '/login'
      localStorage.clear()
    } else {
      ElMessage({
        message: msg,
        type: 'error',
      })
    }
  } else {
    ElMessage({
      message: '出现错误',
      type: 'error',
    })
  }
}
