import Vuex from 'vuex'
import getters from './getters'
// 自动引入 modules 文件夹下的 js 文件
const files = require.context('./modules', false, /\.js$/)
// console.log(files);
const modules = {}
files.keys().forEach(key => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
});
Object.keys(modules).forEach(key => {
  modules[key]['namespaced'] = true
})

const store = new Vuex.Store({
  modules,
  getters
})

export default store

