/** @format */
import http from '@/js/util/http'
import { getUserinfo, setUserinfo } from '@/utils/auth.js'
import router from '@/router/index.js'
const state = () => {
  return {
    token: null,
    userinfo: JSON.parse(getUserinfo()) || {},
    agentInfo: {},
  }
}
const mutations = {
  set_userinfo(state, payload) {
    state.userinfo = payload
    setUserinfo(JSON.stringify(payload))
  },
  set_agentInfo(state, payload) {
    state.agentInfo = payload
  },
  
}
const actions = {
  async getUserinfoApi({commit}) {
    const url = '/business/user/account/getUserInfo'
    const res = await http.post(url, {})
    commit('set_userinfo', res)
    if (!res.username) {
      localStorage.clear()
      sessionStorage.clear()
      router.push('/login')
    }
  },
  logout({commit}) {
    commit('set_userinfo', {})
    sessionStorage.clear()
    localStorage.clear()
    router.push('/login')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
