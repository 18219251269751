/** @format */

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { installPlugins } from '../src/plugins'

// 定义特性标志
window.__VUE_PROD_DEVTOOLS__ = false
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false

const app = createApp(App).use(store).use(router)

// 引入全局样式
import './style/index.scss'
import './style/fonts/font.css'
// 自适应
import '@/js/util/rem.js'

// 安装插件
installPlugins(app)
app.mount('#app')

// highlight 的样式，依赖包，组件
import 'highlight.js/styles/atom-one-dark.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

//注册组件
app.use(hljsVuePlugin)
