<!-- @format -->

<template>
  <div>
    <div v-if="$route.meta.keepAlive">
      <leftSide></leftSide>
      <!-- <nav></nav> -->
      <router-view></router-view>
    </div>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
  import leftSide from '@/components/leftSide.vue'

  export default {
    name: 'App',
    components: {
      leftSide,
    },
  }
</script>
<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  // nav {
  //   a {
  //     font-weight: bold;
  //     color: #2c3e50;

  //     &.router-link-exact-active {
  //       color: #42b983;
  //     }
  //   }
  // }
  /* 修改滚动条样式 */
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #eee;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
</style>
